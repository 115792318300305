import React from "react";
import classNames from "classnames";
import withStyles from "@material-ui/core/styles/withStyles";

import Header from "components/Header/Header.jsx";
import HeaderLinks from "components/Header/HeaderLinks.jsx";
import Footer from "components/Footer/Footer.jsx";
import Parallax from "components/Parallax/Parallax.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";

import landingPageStyle from "assets/jss/material-kit-react/views/landingPage.jsx";

import SectionVideoAnalyticsHorus from "./Sections/sectionVideoAnalyticsHorus";
import SectionVideoAnalyticsLaura from "./Sections/sectionVideoAnalyticsLaura";
import SectionVideoAnalyticsOsiris from "./Sections/sectionVideoAnalyticsOsiris";
import SectionThermal360StoraEnso from "./Sections/sectionThermal360StoraEnso";

//import SectionRobot360Live from "./Sections/sectionRobot360Live";
//import SectionAutoStreetView from "./Sections/sectionAutoStreetView";
//import SectionTimelapse from "./Sections/sectionTimelapse";
//import SectionLiveSYNCFortum from "./Sections/sectionLiveSYNCFortum";
//import SectionESite from "./Sections/sectionESite";

import SectionPhotogrammetry from "./Sections/sectionPhotogrammetry";
import SectionVideogrammetry from "./Sections/sectionVideogrammetry";
import SectionRuskotunturi from "./Sections/sectionRuskotunturi";

import SectionHoivakotiLive from "./Sections/sectionHoivakotiLive";

import SectionVRVideoApps from "./Sections/sectionVRVideoApps";
import SectionOrion360SDK from "./Sections/sectionOrion360SDK";
import SectionLiveSYNCPresent from "./Sections/sectionLiveSYNCPresent";
//import SectionStarterKit360 from "./Sections/sectionStarterKit360";

import SectionCustomers from "./Sections/sectionCustomers";

import SectionContact from "../ContactPage/Sections/sectionContact.jsx";

const dashboardRoutes = [];

class LandingPage extends React.Component {
  render() {
    const { classes, ...rest } = this.props;
    return (
      <div>
        <Header
          color="transparent"
          routes={dashboardRoutes}
          brand="Finwe Ltd."
          rightLinks={<HeaderLinks />}
          fixed
          changeColorOnScroll={{
            height: 900,
            color: "white"
          }}
          {...rest}
        />
        
        <Parallax image={require("assets/img/landing-bg_lowres.jpg")}>
          <div className={classes.container}>
            <GridContainer>
              <GridItem xs={12} sm={12} md={6}>
                <h1 className={classes.title}>Your Video Intelligence Partner.</h1>
                <h4 className={classes.description}>
                  We are a special ops team focusing on intelligent ways of
                  using video: video analytics, 5G live streaming, AI cameras,
                  VR video, videogrammetry... We build custom solutions and 
                  ready-to-use products for industrial, media and business users,
                  and also offer resources for hire.
                </h4>
                <br />
                <Button
                  round
                  color="info"
                  size="lg"
                  href="contact-page/"
                  target="_self"
                  rel="noopener noreferrer"
                >
                  How can we help you?
                </Button>
              </GridItem>
            </GridContainer>
          </div>
        </Parallax>

        <div className={classNames(classes.main, classes.mainRaised)}>

          {/* VIDEO ANALYTICS */}
          <div className={classes.sectionTitle}>
            <h2>Industrial Solutions</h2>
          </div>
          <div className={classes.container}>
            <SectionVideoAnalyticsHorus />
            <SectionVideoAnalyticsLaura />
            <SectionVideoAnalyticsOsiris />
            <SectionThermal360StoraEnso />
          </div>

          {/*
          <div className={classes.sectionTitle}>
            <h2>Advanced Camera Solutions</h2>
          </div>
          <div className={classes.container}>
          <SectionRobot360Live />
            <SectionAutoStreetView />
            <SectionTimelapse />
          </div>
          */}
            {/*
            <SectionLiveSYNCFortum />
            <SectionESite />
            */}

          {/* VIDEOGRAMMETRY */}
          <div className={classes.sectionTitle}>
            <h2>Photo/videogrammetry & SLAM</h2>
          </div>
          <div className={classes.container}>
            <SectionPhotogrammetry />
            <SectionVideogrammetry />
            <SectionRuskotunturi />
          </div>

          {/* TELECONFERENCING */}
          <div className={classes.sectionTitle}>
            <h2>Live Streaming & Teleconferencing</h2>
          </div>
          <div className={classes.container}>
            <SectionHoivakotiLive />
          </div>

          {/* VR VIDEO */}
          <div className={classes.sectionTitle}>
            <h2>VR Video Solutions</h2>
          </div>
          <div className={classes.container}>
            <SectionVRVideoApps />
            <SectionOrion360SDK />
            <SectionLiveSYNCPresent />
            {/*
            <SectionStarterKit360 />
            */}
          </div>

          {/* CUSTOMERS */}
          <div className={classes.sectionTitle}>
            <h2>Customers & Partners</h2>
          </div>
          <div className={classes.container}>
            <SectionCustomers />
          </div>

          {/* CONTACT */}
          <div className={classes.sectionTitle}>
            <h2>Contact Us</h2>
          </div>
          <div className={classes.container}>
            <SectionContact/>
          </div>

        </div>

        {/* FOOTER */}
        <Footer />

      </div>
    );
  }
}

export default withStyles(landingPageStyle)(LandingPage);
